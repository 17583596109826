@media screen and (max-width: 1400px) {
    .container{
        width: 1140px;
    }
    .container-large{
        width: 1140px;
    }
    .container-small{
        width: 965px;
    }
    .slider-home{
        width: 1200px;
    }
    .large-item{
        height: 441px;
    }
}

@media screen and (max-width: 1200px) {
    .container{
        width: 960px;
    }
    .container-large{
        width: 100%;
        padding: 10px;
    }
    .container-small{
        width: 765px;
    }
    .slider-home{
        width: 1000px;
    }
    h2{
        font-size: 40px;
    }
    .product-container{
        flex-direction: column;
    }
    .product-inner{
        max-width: unset;
    }
    .product-buttons{
        justify-content: space-between;
    }

}

@media screen and (max-width: 1100px) {
    .navbar-links{
        display: none;
    }
    .hamburger-react{
        display: block;
    }
    .mobile-menu{
        left: 0;
    }
    .logo-link{
        margin-right: 0;
    }
}

@media screen and (max-width: 992px) {
    .container{
        width: 720px;
        padding: 1rem;
    }
    .container-small{
        width: 665px;
    }
    h1{
        font-size: 42px;
    }
    h2{
        font-size: 30px;
    }
    h3{
        font-size: 24px;
    }
    h4{
        font-size: 18px;
    }
    p{
        font-size: 16px;
    }
    .link-large{
        aspect-ratio: 1;
    }
    .large-item{
        height: auto;
    }
    .news-section-item{
        padding: 1rem;
    }
    .blog-section input{
        min-width: auto;
        width: 100%;
    }
    .blog-flexbox{
        align-items: normal;
        gap: 1rem;
        flex-direction: column;
    }
    .sub-button{
        width: 100%;
    }
    .section-wrapper{
        height: 50vh;
    }
    .section-wrapper-sm{
        height: 30vh;
    }
    .category-grid{
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
    .catalog-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .image-box{
        background-size: cover;
    }
    .product-info .title h2{
        font-size: 40px;
    }
    .product-info .title h4{
        font-size: 26px;
        line-height: 1 !important;
    }
}

@media screen and (max-width: 720px) {
    .container{
        width: 100%;
    }
    .container-small{
        width: 100%;
    }
    .news-flexbox{
        flex-direction: column;
        gap: 1rem;
    }
    .news-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .news-container{
        gap: 1rem;
    }
    .category-grid{
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
    }
    .filters-flexbox{
        flex-direction: column;
    }
    .filters{
        width: 100%;
    }
    .bi-caret-down-fill{
        display: block;
    }
    .image-left{
        padding: 0;
        flex-direction: column-reverse;
    }
    .image-right{
        padding: 0;
        flex-direction: column-reverse;
    }
    .dark-bg{
        padding: 0;
    }
    .in-text-image{
        height: 20rem;
    }
    .footer-flexbox img{
        margin-right: 100%;
    }
    .footer-flexbox{
        gap: 1rem;
        flex-wrap: wrap;
    }
    .footer-flexbox img{
        display: none;
    }
    .product-padding{
        margin-bottom: 0;
    }
    .strap-arrow{
        display: none !important;
    }
}

@keyframes bgFadeIn {
    0% {
        transform: scale(105%);
    }
    100% {
        transform: scale(100%);
    }
}