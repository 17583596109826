@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #1B1A1A;
  background-color: #F7F7F7;
}

p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

p{
  font-size: 18px;
}

h1{
  font-size: 72px;
}

h2{
  font-size: 52px;
}

h3{
  font-size: 36px;
}

h4{
  font-size: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea:focus, input:focus{
  outline: none;
}

*:focus {
  outline: none;
}

a, a:visited, a:hover, a:active {
  font-size: 18px;
  color: inherit;
  text-decoration: none;
}

.navbar-links .active{
  font-weight: 700;
}

::selection {
  background: black;
  color: white;
}

::-moz-selection {
  background: black;
  color: white;
}

.flexbox-column{
  display: flex;
  flex-direction: column;
}

.flexbox-row{
  display: flex;
  flex-direction: row;
}

.flexbox-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

nav{
  width: 100vw;
  position: fixed;
  transition: .3s;
  z-index: 2;
  top: 0;
}

.nav-wrap{
  width: 100vw;
  height: 88px;
  position: fixed;
  background-color: white;
  z-index: 1;
  top: -100px;
  transition: .3s;
}

nav:hover{
  color: #1B1A1A;
}

.nav:hover > .nav-wrap{
  top: 0;
}

.nav-active{
  color: #1B1A1A;
}

.nav-not-active{
  background: transparent;
  color: white;
}

.container{
  width: 1320px;
  margin: auto;
  padding: 2rem;
}

.container-large{
  width: 1320px;
  margin: auto;
  padding: 2rem;
}

.container-small{
  width: 1120px;
  margin: auto;
  padding: 2rem;
}

.navbar-container{
  height: 88px;
  padding: 1.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a{
  font-size: 20px;
  transition: 0.5s;
}

nav a:hover{
  font-size: 20px;
  color: #298bff;
}

.navbar-links{
  display: flex;
  gap: 10px;
}

.logo-link{
  margin-right: 6rem;
}

.links-right{
  gap: 30px;
}

.hamburger-react {
  display: none;
}

.nav-active ~ .hamburger-react{
  color: red
}
.nav-not-active ~ .hamburger-react{
  color: white;
}

.mobile-menu{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 6rem 2rem;
  left: 100vw;
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: white;
  transition: 0.4s;
  z-index: 1;
  overflow-y: scroll;
}

.mobile-navbar-links{
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.mobile-navbar-links a{
  color: #1B1A1A;
  font-size: 48px;
  text-decoration: none;
}

.links-bottom{
  display: flex;
  gap: 30px;
  margin-top: 1rem;
}

.bi{
  font-size: 24px;
  transition: .3s;
}

.bi-search{
  top: 1px;
  position: relative;
}

.nav-active ~ .bi{
  color: #1B1A1A
}

.nav-not-active ~ .bi{
  color: white;
}

.shop-section{
  min-height: 100vh;
}

.bi-caret-down-fill{
  display: none;
  font-size: 21px;
}

.filters{
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #FFFFFF;
  width: 268px;
  padding: 2rem;
  transition: .3s;
  overflow: hidden;
}

.filters-block{
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.filters-block-row{
  display: flex;
  flex-direction: row;
  margin-top: -20px;
}

.filters-flexbox{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.filters-item{
  display: flex;
  align-items: center;
  gap: 10px;
}

.catalog-grid{
  width: 100%;
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.catalog-item{
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 1rem;
  transition: .3s;
}

.catalog-item > img{
  transition: .3s;
}

.catalog-item:hover > img{
  transform: scale(102%);
}

.catalog-link{
  transition: .2s;
}

.catalog-link:hover{
  font-weight: 700;
}

.not-stocked{
  color: #c2c2c2 !important;
  pointer-events: none;
}

.not-stocked > img{
  opacity: 0.6;
}

.product-section{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  height: 100vh;
  color: white;
  gap: 10px;
}

.product-video{
  height: fit-content;
  position: absolute;
}

.image-box{
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: .3s;
  animation: bgFadeIn 1s ease-out;
}

.text-box{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFFFFF;
  height: 100%;
  text-align: center;
  padding: 2rem;
}

.section-wrapper{
  height: 75vh;
  overflow: hidden;
}

.section-wrapper-sm{
  height: 40vh;
  overflow: hidden;
}

.desc{
  letter-spacing: 5px;
  font-weight: 300;
}

.button-list{
  display: flex;
  gap: 30px;
  padding: 25px;
}

.button-product{
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: #1B1A1A;
  width: calc(9rem + 5vw);
  padding: calc(1.625rem + 0.325vw) 0;
  border: none;
  transition: 0.3s;
}

.button-product:hover{
  background-color: #312d2d;
}

.sale-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #1B1A1A;
  text-align: center;
  overflow: hidden;
}

.slider-home{
  width: 1400px;
  padding: 2rem 4rem;
}

.slider-product{
  width: 540px;
  margin: 2rem;
}

.sale-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  aspect-ratio: 3/4;
  transition: .3s;
  background-color: white;
  margin: 0 10px;
}

.sale-card img{
  transition: .3s;
  padding: 0.625rem;
}

.sale-card img:hover{
  transform: scale(105%);
}

.sale-card-info{
  background-color: white;
  padding: 1rem;
  transition: .3s;
}

.sale-card-info:hover{
  background-color: #e5e5e5;
}

.discounted{
  font-size: 14px;
  color: rgb(128, 128, 128);
  text-decoration: line-through;
}

.text-gray{
  color: rgb(128, 128, 128);
}

.slick-list {
  margin: 0 -10px;
}

.news-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.news-container{
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem 2rem;
  text-align: center;
  gap: 30px;
}

.news-section-item{
  width: 100%;
  background-color: white;
  aspect-ratio: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  transition: .3s;
  background-position: center;
  color: white;
}

.news-section-link{
  width: 100%;
  background-color: white;
  aspect-ratio: 1;
  display: flex;
  overflow: hidden;
  transition: .3s;
}

.link-large{
  aspect-ratio: unset;
  background-color: transparent;
}

.news-section-link:hover >.large-item{
  background-size: 103%;
}

.news-section-link:hover > .small-item{
  background-size: 155%;
  background-position: 2% center;
}

.news-flexbox{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.large-item{
  height: 615px;
  aspect-ratio: auto;
  background-position: center;
  background-size: 100%;
}

.small-item{
  background-size: 152%;
  background-position: 0 center;
}

.bi-arrow-right{
  opacity: 0;
}

.show-news{
  background-color: white;
  transition: .3s;
  height: 5rem;
}

.show-news a{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.show-news:hover{
  color: white;
  background-color: #298bff;
}

.blog-section{
  width: 100%;
  background-color: white;
}

.blog-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.325rem
}

.blog-flexbox{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.blog-flexbox input{
  min-width: 32rem;
  background-color: #f7f7f7;
  border: none;
  padding: 1.625rem;
  font-size: 18px;
}

.blog-flexbox input::placeholder{
  color: rgba(0, 0, 0, 0.39);
}

.footer{
  color: white;
  background-color: #1B1A1A;
}

.footer-flexbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-flexbox img{
  width: 50px;
  height: 33px;
  margin-right: 95px;
}

.footer-logos{
  gap: 15px;
}

.footer-logos .bi{
  cursor: pointer;
}

.footer-logos .bi:hover{
  color: #298bff;
}

.news-grid{
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(2, 1fr);
}

.article-flexbox{
  width: 75%;
}

.category-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  aspect-ratio: 1;
  padding: 2rem;
  background-color: white;
  gap: 15px;
  transition: .3s;
}

.category-item:hover{
  transform: scale(102%);
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox-checkmark {
  background-color: #298bff;
}
.checkbox.style-c input:checked ~ .checkbox-checkmark:after {
  opacity: 1;
}
.checkbox.style-c:hover input ~ .checkbox-checkmark {
  background-color: #eee;
}
.checkbox.style-c:hover input:checked ~ .checkbox-checkmark {
  background-color: #298bff;
}
.checkbox.style-c .checkbox-checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.checkbox.style-c .checkbox-checkmark:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid #c9c9c9;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox.style-c .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
}

.category-grid{
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem 0;
}

.category-image{
  width: 100%;
  aspect-ratio: 1;
  transition: .3s;
}

.category-image:hover{
  transform: scale(105%);
}

.button-footer{
  cursor: pointer;
  border: none;
  padding: 1rem 1.625rem;
  font-weight: 600;
  transition: 0.3s;
}

.button-product:hover{
  background-color: #298bff;
}

.about-section{
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.in-text-image{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 40rem;
}

.dark-bg{
  background-color: #f1f1f2;
}

.image-left{
  flex-direction: row;
}

.image-right{
  flex-direction: row-reverse;
}

.about-text{
  padding: 2rem;
  width: 100%;
}

.product-container{
  display: flex;
  align-items: center;
  background-color: white;
  overflow: hidden;
}

.product-inner{
  max-width: 550px;
}

.product-buttons{
  align-items: center;
  gap: 2rem;
}

.product-padding{
  margin-bottom: 2rem;
  background-color: white;
}

.color-select{
  width: 1.325rem;
  height: 1.325rem;
  border-radius: 100%;
  border: 1px solid #00000021;
}

.spec-button{
  transition: .3s;
}

.spec-button:hover{
  color: #6b6b6b;
}

.product-path{
  margin: 2rem;
  letter-spacing: 4px;
  color: #6b6b6b;
}

.product-path a{
  transition: .3s;
}

.product-path a:hover{
  color: #298bff;
}

.product-info p{
  color: #575757;
  font-size: 16px;
}

.slick-arrow .bi{
  color: #1B1A1A;
  top: -22px;
  position: relative;
}

.product-desc{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.specifications-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.specifications-grid .item{
  margin: 0 1rem 2rem 0;
}

.price-input{
  padding: 6px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.price-input input{
  border: none;
  width: 4rem;
  font-size: 15px;
}

.price-merger{
  margin-top: -8px;
  display: flex;
  flex-direction: column;
  color: gray;
}

.home-about{
  height: 95vh;
  overflow: hidden;
}

.home-about-inner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.secondary-button{
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: white;
  width: calc(12rem + 5vw);
  padding: calc(1.625rem + 0.325vw) 0;
  border: 1px solid white;
  background: transparent;
  transition: 0.3s;
}

.secondary-button:hover{
  border: 1px solid #298bff;
  background-color: #298bff;
}